import "./news-card-main.scss";
import React from "react";
import PostAuthor from "components/PostAuthor";
import PostDate from "components/PostDate";
import PostCategories from "components/PostCategories";
import Divider from "components/Divider";
import { Link } from "gatsby";
import LazyLoad from "react-lazy-load";

const NewsCardMain = ({
  title,
  excerpt,
  categories,
  authorName,
  authorAvatar,
  date,
  thumbnail,
  url,
  update,
}) => {
  return (
    <div className="news-card-main">
      <div className="row">
        <div className="col-md-4">
          <Link to={url}>
            <LazyLoad>
              <div
                className="news-card-main__thumbnail"
                style={{ backgroundImage: `url(${thumbnail})` }}
              />
            </LazyLoad>
          </Link>
        </div>
        <div className="col-md-8">
          <div className="news-card-main__content">
            <Link to={url}>
              <h2 className="news-card-main__title">{title}</h2>
            </Link>
            <Link to={url}>
              <div
                className="news-card-main__text"
                dangerouslySetInnerHTML={{ __html: excerpt }}
              />
            </Link>
            <Link to={url} className="arrow-link">
              Czytaj dalej
            </Link>
            <Divider marginTop={30} marginBottom={15} />
            <div className="news-card-main__author-date-row">
              <div className="info">
                <PostAuthor avatar={authorAvatar} name={authorName} />
                <PostCategories categories={categories} />
              </div>
              <div className="date">
                <PostDate date={date} />
                <PostDate
                  date={update ? update?.replaceAll("/", ".") : date}
                  update
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsCardMain;
